import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import DcodeMarkerAutomated from "../../components/sections/DcodeMarkerAutomated/DcodeMarkerAutomated";
import {getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import BackToTechnologyButton from "../../components/shared/ButtonBackTo/BackToTechnologyButton";

const Dma = () => {
    const routes = getRoutes();
    const parentTitle = routes.Technology.pageTitle;
    const title = routes.TechnologyDMASAutomated.pageTitle;
    const sectionAnchor = getRoutes().Technology.sections.markers.anchor

    return (
        <Layout>
            <SEO title={title}/>

            <Breadcrumbs
                title={title}
                parentTitles={[parentTitle]}
                parentRoutes={[routes.Technology]}
                sectionAnchors={[sectionAnchor]}
            />

            <DcodeMarkerAutomated/>

            <BackToTechnologyButton sectionAnchor={sectionAnchor}/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default Dma;
